<template>
  <div>
    <div class="row gutter-b">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header card-header-fixed border-0 px-6">
            <div class="row align-items-center col-8 px-0">
              <div class="col-4 pr-0">
                <div class="input-icon">
                  <input
                    type="text"
                    class="form-control form-control-solid"
                    :placeholder="$t('mealsystem.placeholder.search')"
                    v-model="search"
                  />
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </div>

              <b-button
                variant="secondary"
                class="mr-3 ml-3 pr-2"
                @click.prevent="removeFilters"
              >
                <i class="fa fa-times icon-md"></i>
              </b-button>
              <b-button variant="primary" @click.prevent="searchCards">
                {{ $t("button.filter") }}
              </b-button>
            </div>
            <div class="card-toolbar">
              <span
                class="btn btn-success"
                v-b-modal.modal-card-edit
                @click="usetCard()"
              >
                <span class="svg-icon svg-icon-md svg-icon-white">
                  <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
                </span>
                {{ $t("mealsystem.cards.button.create") }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::Card-->
    <div class="card card-custom">
      <!--begin::Body-->
      <div class="card-body">
        <div class="table-responsive">
          <table
            class="table table-head-custom table-head-bg table-vertical-center"
          >
            <thead>
              <tr class="text-left text-uppercase">
                <th width="20"></th>
                <th>{{ $t("mealsystem.cards.label.card_number") }}</th>
                <th>{{ $t("mealsystem.cards.label.hex_code") }}</th>
                <th>{{ $t("mealsystem.cards.label.assigned_dinner") }}</th>
                <th width="20"></th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="(card, index) in cards.data" :key="index">
                <td class="pl-0">
                  <a
                    class="
                      btn
                      btn-link
                      btn-sm
                      btn
                      btn-clean
                      btn-hover-light-primary
                      btn-sm
                      btn-icon
                    "
                    v-b-tooltip.right="$t('button.settings')"
                    v-b-modal.modal-card-edit
                    @click="setCard(card)"
                    ><i class="ki ki-gear icon-md"></i
                  ></a>
                </td>
                <td>
                  <span class="font-weight-bold">{{ card.card_number }}</span>
                </td>
                <td>
                  <span v-if="card.hex_code">{{ card.hex_code }}</span>
                  <span v-else class="text-muted">-</span>
                </td>

                <td>
                  <span v-if="card.diner"><em class="text-success fa fa-check mr-2"></em>  {{ card.diner.first_name }} {{ card.diner.last_name }} <span class="text-muted">({{ card.diner.diner_number }})</span></span>
                  <span v-else class="text-muted">-</span>
                </td>

                <td>
                  <a
                    class="
                      btn
                      btn-link
                      btn-sm
                      btn
                      btn-clean
                      btn-hover-light-danger
                      btn-sm
                      btn-icon
                    "
                    v-b-tooltip.left="$t('mealsystem.cards.title.delete_card')"
                    v-b-modal.modal-card-delete
                    @click="setCard(card)"
                    ><i class="ki ki-bold-close icon-sm text-danger"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination :data="cards.meta" v-if="!loading"></Pagination>
        </div>
      </div>
      <!--end::Body-->
    </div>
    <!--end::Card-->

    <b-modal
      id="modal-card-delete"
      :okTitle="$t('button.delete')"
      okVariant="danger"
      :title="$t('mealsystem.cards.title.confirm_delete_card_title')"
    >
      <span
        v-html="$t('mealsystem.cards.title.confirm_delete_card_text')"
      ></span>
      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()" class="mr-3">
          {{ $t("button.cancel") }}
        </b-button>
        <b-button variant="danger" @click="deleteCard(selectedCard)">
          {{ $t("mealsystem.cards.button.delete_card") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-card-edit"
      :okTitle="$t('button.save')"
      okVariant="success"
      :title="$t('mealsystem.cards.title.edit_card')"
    >
      <ValidationObserver ref="form">
        <ValidationProvider
          name="card_number"
          rules="required|min:3"
          v-slot="{ errors }"
        >
          <span class="text-muted mb-2 d-block">{{
            $t("mealsystem.cards.label.card_number")
          }}</span>
          <input
            type="text"
            class="form-control form-control-lg form-control-solid"
            :placeholder="$t('mealsystem.cards.placeholder.card_number')"
            v-model="selectedCard.card_number"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
        <ValidationProvider
          name="hex_code"
          rules="required|min:3"
          v-slot="{ errors }"
        >
          <span class="text-muted mt-5 mb-2 d-block">{{
            $t("mealsystem.cards.label.hex_code")
          }}</span>
          <input
            type="text"
            class="form-control form-control-lg form-control-solid"
            :placeholder="$t('mealsystem.cards.placeholder.hex_code')"
            v-model="selectedCard.hex_code"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </ValidationObserver>

      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()" class="mr-3">
          {{ $t("button.cancel") }}
        </b-button>
        <b-button variant="success" @click="updateCard(selectedCard)">
          {{ $t("mealsystem.cards.button.save_card") }}
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Pagination from "@/view/content/Pagination";
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";

export default {
  data() {
    return {
      loading: false,
      cards: null,
      selectedCard: {},
      page: 1,
      search: "",
    };
  },
  components: {
    Pagination
  },
  beforeMount() {
    this.getCards();
  },
  metaInfo() {
    return { title: this.$t("mealsystem.meta.diners_cards") };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("mealsystem.meta.diners"),
        subtitle: this.$t("mealsystem.meta.diners_cards"),
      },
    ]);
  },
  computed: {},
  methods: {
    getCards() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/meal-system/diners/cards?search=" + this.search).then((response) => {
        this.cards = response.data;
        this.loading = false;
      });
    },
    setCard(card) {
      this.selectedCard = card;
    },
    usetCard() {
      this.selectedCard = {};
    },
    deleteCard(card) {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiPost("/meal-system/diners/cards/" + card.card_id + "/delete")
        .then(() => {
          this.loading = false;
          this.$bvModal.hide("modal-card-delete");
          this.getCards();
        })
        .catch((error) => {
          checkErrors(error);
        });
    },
    updateCard(card) {
      this.loading = true;
      this.$refs.form.validate().then((success) => {
        if (!success) {
          this.loading = false;
          this.$toasted.error("Zkontrolujte zadané údaje.");
          return;
        }

        if (card.card_id) {
          this.endpoint = "/meal-system/diners/cards/" + card.card_id + "/update";
        } else {
          this.endpoint = "/meal-system/diners/cards";
        }

        ApiService.setHeader();
        ApiService.apiPost(this.endpoint, {
          card_number: card.card_number,
          hex_code: card.hex_code,
        })
          .then(() => {
            this.loading = false;
            this.$bvModal.hide("modal-card-edit");
            this.getCards();
          })
          .catch((error) => {
            checkErrors(error);
          });
      });
    },
    searchCards() {
      this.page = 1;
      this.getCards();
      this.cardSearch = {
        search: this.search,
      };
    },
    removeFilters() {
      this.page = 1;
      this.search = "";
      this.cardSearch = {
        search: this.search,
      };
      this.getCards();
    },
  },
};
</script>
